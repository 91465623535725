@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Bold";
  src: local("SFPRODISPLAYBOLD"),
    url("./fonts/SFPRODISPLAYBOLD.woff") format("woff");
}
@font-face {
  font-family: "Regular";
  src: local("SFPRODISPLAYREGULAR"),
    url("./fonts/SFPRODISPLAYREGULAR.woff") format("woff");
}
@font-face {
  font-family: "Light";
  src: local("SFProDisplay-Light"),
    url("./fonts/SFProDisplay-Light.woff") format("woff");
}
:root {
  --color-primary: #000;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

/* Typography */

html {
  font-size: 62.5%;

}

img {
  width: 100%;
}
html,body{
  overflow-x: hidden;
}

body {
  position: relative;
  font-family: "Light", sans-serif;
  font-size: 2rem;
  line-height: 1.5;
  background: #fff;
}

h1,
h2,
h3,
h4 {
  font-family: "Bold", sans-serif;
  line-height: 1.1;
}

h1 {
  font-size: 6rem;
}

h2 {
  font-size: 4.2rem;
}

h3 {
  font-size: 3rem;
}
h4 {
  font-size: 1.8rem;
}


p {
  margin-top: 0;
}

@media screen and (min-width: 1024px) {
  body {
    font-size: 2.5rem;
  }

  h1 {
    font-size: 8rem;
  }
  
  h2 {
    font-size: 6.2rem;
  }
  
  h3 {
    font-size: 4.7rem;
    line-height: 1.3;
  }
  h4 {
    font-size: 2.2rem;
  }
}

/* Links */
a {
  text-decoration: none;
}

::-webkit-scrollbar {
  display: none;
}
