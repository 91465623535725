
#departments{
    height: 100vh;
    background: rgb(239, 239, 255);
}
.departments{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.title{
  padding-top: 28px;
  padding-bottom: 20px;
  align-items: center;
}

.title>h2{
  margin: 5px !important;
}

.department-list{
  height: 100%;
  width: calc(100% - 200px);
}
.list-up{
  display: flex;
  flex-wrap: nowrap;
  overflow:scroll;
  height: 50%;
  padding-bottom: 15px;
}
.list-down{
  display: flex;
  flex-wrap: nowrap;
  overflow:scroll;
  height: 50%;
  padding-bottom: 15px;
}

.list-up::-webkit-scrollbar{
  display: none;
}
.list-down::-webkit-scrollbar{
  display: none;
}
.department-item{
  background: transparent;
  box-shadow: 0 3px 20px rgb(0 0 0 / 0.2);
  cursor: pointer;
  width: 350px;
  height: 230px;
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: 0.3s;
}

.department-item:hover{
  transform: translateY(-8px);
  
}

.department-name{
  margin: auto;
  padding-bottom: 20px;
}

.department-icon{
  padding-top: 30px;
}

.department-list-mobile{
  width: 100%;
  display: flex;
  height: calc(100vh - 104px);
  align-items: center;
  display: none;
}

.slider {
  width: 100%;
  text-align: center;
  overflow: hidden;
}

.slides {
  display: flex;
  width: 100%;
  height: calc(100vh - 150px);
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.slides::-webkit-scrollbar {
  display:none;
}
.slides > div {
  margin-top: 15px;
  scroll-snap-align: start;
  flex-shrink: 0;
  
  cursor: pointer;
  height: calc(100vh - 300px);
  width: 100%;
  border-radius: 10px;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.department-container-mobile{
  height: 90%;
  width: 90%;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 50px;
  box-shadow: 0 3px 20px rgb(0 0 0 / 0.2);
  display: flex;
  align-items: center;
}

.department-item-mobile{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.department-icon-mobile{
  width: 100%;
}
.department-icon-mobile > img {
  width: 100px;
  height: 100px;
  margin: auto;
}

@media only screen and (max-width: 768px){
  .department-list{
    display: none;
  }
  .department-list-mobile{
    display: block;
  }
}