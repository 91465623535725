.hero {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 60px;
}

.container {
  max-width: 1250px;
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 110px;
}

.hero-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.hero_content {
  padding-top: 70px;
}

.hero_h1,
.hero_h2 {
  margin-bottom: 0px;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.54);
}

.hero_h2 {
  margin-top: 10px;
}

.hero_tagline {
  color: rgb(36, 36, 36);
  margin-top: 25px;
  margin-left: 10px;
  margin-bottom: 35px;
}

.hero_logo {
  margin-left: 25px;
  width: 40rem;
  height: 43.599rem;
  scale: 1.3;
}

@media screen and (max-width: 1200px) {
  .hero_logo {
    width: 32rem;
    height: 34.7rem;
    scale: 1.3;
  }
  .hero_content {
    padding-top: 50px;
  }
}

@media screen and (max-width: 1050px) {
  .hero {
    padding-top: 10px;
    padding-bottom: 0px;
  }
  .hero-container {
    padding-top: 0px;
    padding-bottom: 80px;
  }
  .hero_logo {
    width: 25rem;
    height: 27.155rem;
    scale: 1.3;
  }
  .hero_content {
    padding-top: 50px;
  }
}
@media screen and (max-width: 800px) {
  .hero {
    padding-bottom: 30px;
  }
  .hero_logo {
    width: 25rem;
    height: 27.155rem;
    scale: 1.3;
  }
  .hero_content {
    padding-top: 0px;
  }
  .hero-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    justify-items: center;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 560px) {
  .hero_logo {
    margin-left: 0px;
    width: 25rem;
    height: 27.155rem;
    scale: 1.3;
  }
  .hero_content {
    padding-top: 10px;
  }
  .hero-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    justify-items: center;
    padding-top: 30px;
    padding-bottom: 0;
  }
  .hero_h1 {
    font-size: 4rem;
  }
  .hero_h2 {
    font-size: 4rem;
  }
  .hero_tagline {
    font-size: 1.5rem;
  }
}

@media screen and (min-width: 1560px) {
  .hero {
    background-size: cover;
  }
}
