/*.navbar_container {
  background-image: url("/images/nav_bg.webp");
}*/
.navbar-logo > img {
  width: 38rem;
  height: 5.332rem;
}

.navbar {
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  margin-left: 5%;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  font-size: 1.8rem;
  font-family: "Bold";
  color: #000;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 2.5rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #000;
  transition: all 0.25s ease-out;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .navbar-logo > img {
    width: 25rem;
    height: 3.504rem;
  }
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }
  

  .nav-menu.active {
    background: rgba(0, 0, 0, 0.688);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    color: rgb(202, 202, 202);
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    transition: transform 0.3s, color 0.3s;
  }

  .nav-links:hover {
    border-bottom: none;
    color: rgb(255, 255, 255);
    transform: scale(1.15);
  }

  .navbar-logo {
    margin-left: 5%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    cursor: pointer;
  }
}

@media screen and (min-width: 1560px) {
  .navbar{
    background-size: cover;
  }
  .navbar-logo {
    margin-left: 15%;
  }
}