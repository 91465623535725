:root {
  --primary: #394aff;
}

.btn {
  display: block;
  border-radius: 40px;
  border: 0;
  cursor: pointer;
  font-size: 1.8rem;
  margin: 1rem 0;
  outline: 0;
  padding: 1.2rem 2.7vw;
  text-align: center;
  white-space: nowrap;
}

.btn--primary {
  background-color: var(--primary);
  color: white;
  letter-spacing: 1px;
  font-weight: 500;
  transition: transform 0.3s;
  margin-left: 10px;
}
.btn--primary:hover {
  transform: scale(1.05);
}
@media screen and (max-width: 560px) {
  .btn{
    font-size: 1.2rem;
    padding: 0.6rem 2vw;
  }
}
