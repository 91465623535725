.events-container{
    min-height: 100vh;
}

.events-title{
    padding-top: 28px;
    padding-bottom: 20px;
    text-align: center;
}

.events-list{
    padding: 30px 50px 30px 50px;
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: baseline;
}

.event-card{
    cursor: pointer;
    box-shadow: 0 3px 20px rgb(0 0 0 / 0.2);
    height: 150px;
    width: 130px;
    padding: 15px;
    align-items: center;
    margin: 7px;
    border-radius: 20%;
    transition: 0.5s;
}

.event-card:hover{
    box-shadow: 0 3px 20px rgb(0 0 0 / 0.5);
    
}

.event-title{
    font-size: 2rem;
    text-align: center;
    font-weight: 700;
}

.event-img-small{
    width: 100px;
    height: 100px;
}

.event-img-small > img{
    width: 100px;
    height: 100px;
    border-radius: 20%;
}


.extended-card{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 50vh;
    width: 70%;
    background-color: white;
    box-shadow: 0 3px 20px rgb(0 0 0 / 0.2);
    border-radius: 30px;
    display: flex;
    align-items: center;
}

.close-button{
    position: relative;
    left: 95%;
    top: -40%;
}

.close-button > span > img{
    height: 20px;
    width: 20px;
}

.extended-img{
    height: 40vh;
    margin: 30px;
}

.extended-img > img{
    height: 40vh;
    aspect-ratio: 1;
    border-radius: 20px;
}
.extended-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    width: 50%;
    margin: auto;
}
.extended-title{
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
}
.extended-desc{
    text-align: center;
    font-size: 2rem;
}
.extended-mobile-img{
    display: none;
    width: 70%;
}
.extended-mobile-img > img{
    border-radius: 18px;
}

@media only screen and (max-width: 768px){
    .extended-img{
        display: none;
    }
    .extended-mobile-img{
        display: block;
    }
    .extended-card{
        height: 80vh;
    }
    .extended-content{
        width: 100%;
        margin: auto;
    }
    .extended-desc{
        padding: 20px;
    }
    .close-button{
        left: 91%;
        top: -45%;
    }
}