.stats {
  background-color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.stats-container {
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.stats-comp {
  text-align: center;
}
.stats-comp_h1 {
  color: black;
  font-family: "Bold", sans-serif;
  font-size: 8rem;
  line-height: 1;
}

.stats-comp > p {
  color: rgb(36, 36, 36);
  font-size: 2.3rem;
}

@media screen and (max-width: 560px) {
  .stats{
    padding-top: 40px;
    padding-bottom: 80px;
  }
  .stats-comp_h1 {
    font-size: 4rem;
  }
  .stats-comp > p {
    font-size: 1.5rem;
  }
}
